<template>
  <div class="cw-tupas">
    <v-toolbar
      v-if="mobile"
      id="cw-tupas__toolbar"
      dark
      class="primary"
    >
      <v-toolbar-title>
        <translate>
          Verify your identity
        </translate>
      </v-toolbar-title>
      <v-spacer/>
    </v-toolbar>
    <v-card-title v-if="!mobile" class="text-h5">
      <translate>
        Verify your identity
      </translate>
    </v-card-title>
    <v-card-text v-if="banksCount === 1">
      <p class="mb-6">
        <translate>
          Please authenticate with your personal online banking IDs or with the Mobile ID.
        </translate>
      </p>
      <v-row class="cw-tupas__items--inner relative">
        <template v-for="bank in banks.data">
          <v-col v-if="(/^form-/).test(bank.type)" :key="bank.formName" class="cw-tupas__item pa-0">
            <form
              :id="bank.formName"
              :action="bank.targetUrl"
              :name="bank.formName"
              :method="bank.type.split('-')[1]"
            >
              <input
                v-for="(value, name) in bank.data"
                :key="name"
                :name="name"
                :value="value"
                type="hidden"
              >
              <v-btn
                :id="`${bank.formName}link`"
                color="accent"
                class="cw-tupas-dialog__action"
                @click="submitForm(bank)"
              >
                <translate>
                  Start authentication
                </translate>
              </v-btn>
            </form>
          </v-col>
        </template>
        <cw-form-loader v-if="$wait.waiting('TUPAS__INITIALIZING')"/>
      </v-row>
    </v-card-text>
    <v-card-text v-else class="cw-tupas__items">
      <p class="mb-6">
        <translate>
          Please authenticate with your personal online banking IDs or with the Mobile ID.
        </translate>
      </p>
      <v-row class="cw-tupas__items--inner relative">
        <template v-for="bank in banks.data">
          <v-col v-if="(/^form-/).test(bank.type)" :key="bank.formName" class="cw-tupas__item pa-0">
            <form
              :id="bank.formName"
              :action="bank.targetUrl"
              :name="bank.formName"
              :method="bank.type.split('-')[1]"
            >
              <input
                v-for="(value, name) in bank.data"
                :key="name"
                :name="name"
                :value="value"
                type="hidden"
              >
              <div
                v-ripple
                class="bank"
              >
                <a
                  :id="`${bank.formName}link`"
                  :style="`
                    background-image:url(${
                    require(`@shared/assets/images/identification/${bank.profile}.gif`)
                  });
                  `"
                  class="tupaslink"
                  href="#"
                  @click.prevent="
                    submitForm(bank);
                    $eventLogger.clickEvent($event);
                  "
                />
                <span
                  v-if="bank.altText"
                  class="bankText"
                  @click="submitForm(bank)"
                >
                  {{ bank.altText }}
                </span>
              </div>
            </form>
          </v-col>
        </template>
        <cw-form-loader v-if="$wait.waiting('TUPAS__INITIALIZING')"/>
      </v-row>
    </v-card-text>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { DataLayer } from '@shared/services/dataLayer';

export default {
  name: 'CwTupasButtons',

  props: {
    /**
     * Tupas data.
     */
    route: {
      type: String,
      default: () => '',
    },
  },

  data: () => ({
    banks: [],
    banksCount: 0,
  }),

  computed: {
    ...mapGetters({
      mobile: 'getMobile',
    }),
  },

  async created() {
    this.$wait.start('TUPAS__INITIALIZING');
    this.banks = await this.getIdentificationData({ id: this.route });
    this.banksCount = (this.banks.data) ? Object.keys(this.banks.data).length : 0;
    this.$wait.end('TUPAS__INITIALIZING');
  },

  methods: {
    ...mapActions({
      getIdentificationData: 'identification/getIdentificationData',
    }),
    submitForm(bank) {
      DataLayer.checkoutOption({
        option: bank.profile,
      });

      document.getElementById(bank.formName).submit();
    },
  },
};
</script>

<style lang="scss" scoped>
.cw-tupas__items {
  background: #fff;

  &--inner {
    position: relative;
    min-height: 536px;
  }
}

.cw-tupas__item {
  border-top: 1px #e5e5e5 solid;
  border-right: 1px #e5e5e5 solid;
  min-width: 33.33%;
  max-width: 100%;
  width: 100%;

  form,
  .bank {
    width: 100%;
    height: 100% !important;
  }

  .bank {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.1s;
  }

  @include mq($until: sm) {
    min-width: 50%;
    width: 50%;

    &:nth-child(2n) {
      border-right: 1px transparent solid;
    }

    &:nth-child(-n+2) {
      border-top: 1px transparent solid;
    }
  }

  @include mq($from: sm) {
    min-width: 33%;
    width: 33%;

    &:nth-child(3n) {
      border-right: 1px transparent solid;
    }

    &:nth-child(-n+3) {
      border-top: 1px transparent solid;
    }
  }

  &:last-child {
    border-right: 1px transparent solid;
  }

  &__container {
    margin-bottom: 1em;
    margin-top: 1em;
    text-align: center;
    padding: 0;
    cursor: pointer;
  }

  &__label {
    color: #666;
  }

  &__link {
    display: flex;
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 105px;
  }
}

.tupaslink {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  height: 105px;
  width: 100%;
}

</style>
